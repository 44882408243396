import {generalColors} from '@elanco/component-library-v2/dist/Colors/generalColors'
import {brandColors} from '@elanco/component-library-v2/dist/Colors/brandColors'

export function getColorHex(colorKey: string): string | undefined {
	if (colorKey in generalColors) {
		return generalColors[colorKey]?.backgroundColor
	}

	if (colorKey in brandColors) {
		return brandColors[colorKey]?.backgroundColor
	}

	return undefined
}
